import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  isSmallerScreen = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  menus = [
    { name: 'Home', route: '/', icon: 'home' },
    { name: 'Services', route: '/services', icon: 'share' },
    { name: 'Technologies', route: '/technologies', icon: 'folder_shared' },
    { name: 'Portfolio', route: '/portfolio', icon: 'security' },
    { name: 'Contact Us', route: '/contacts', icon: 'info' }
  ];

  constructor(private breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      '(max-width: 960px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.isSmallerScreen = true;
      } else {
        this.isSmallerScreen = false;
      }
    });
  }

  ngOnInit(): void {

  }
}
