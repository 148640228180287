import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './features/home/home.component';
import {PortfolioComponent} from './features/portfolio/portfolio.component';
import {ServicesComponent} from './features/services/services.component';
import {TechnologiesComponent} from './features/technologies/technologies.component';
import {ContactUsComponent} from './features/contact-us/contact-us.component';
import {LocationInfoPrivacyPolicyComponent} from './features/location-info-privacy-policy/location-info-privacy-policy.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'services', component: ServicesComponent},
  {path: 'technologies', component: TechnologiesComponent},
  {path: 'portfolio', component: PortfolioComponent},
  {path: 'contacts', component: ContactUsComponent},
  {path: 'my-location-info-privacy-policy', component: LocationInfoPrivacyPolicyComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
