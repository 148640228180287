<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav side-nav mat-elevation-z3" fixedInViewport="true"
               [attr.role]="'navigation'"
               [mode]="'over'"
               [opened]="false">

    <mat-divider></mat-divider>

    <mat-nav-list class="nav-menu-item">

      <a *ngFor="let menu of menus" class="a-menu d-flex p-3 nav-link app-nav-link" routerLink="{{ menu.route }}"
         routerLinkActive="active" (click)="drawer.close()">
        <!--        <mat-icon aria-label="Side nav toggle icon" class="app-nav-link">{{ menu.icon }}</mat-icon>-->
        <p class="mx-4 app-nav-link">{{ menu.name }}</p>
      </a>

    </mat-nav-list>

  </mat-sidenav>
  <mat-sidenav-content style="background-color: white">
    <section class="d-flex align-items-center" #header id="header">
      <div class="container d-flex align-items-center">

        <div class="logo">

          <div class="vallift-brand">
            <a class="logo" href="#"><img height="60" src="../../../assets/images/branding/vallift-innovations-logo.svg"
                                          alt="logo"></a>

            <div class="name">
              <span class="vallift">Vallift</span>
              <span class="innovations">Innovations</span>
            </div>

          </div>
        </div>

        <div class="flex-grow-1"></div>

        <nav id="nav-menu-container" *ngIf="!(isSmallerScreen)">
          <ul class="nav-menu">
            <li><a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/">Home</a></li>
            <li><a routerLinkActive="active" routerLink="/services">Services</a></li>
            <li><a routerLinkActive="active" routerLink="/technologies">Technologies</a></li>
            <li><a routerLinkActive="active" routerLink="/portfolio">Portfolio</a></li>
          </ul>

        </nav>

        <span *ngIf="!(isSmallerScreen)"><a class="btn-contact-us" routerLink="/contacts">Contact Us</a></span>

        <button mat-icon-button type="button" class="text-secondary ml-2" aria-label="Toggle sidenav"
                (click)="drawer.toggle()"
                *ngIf="(isSmallerScreen)">
          <mat-icon class="menu-icon" *ngIf="drawer.opened" matTooltip="Close sidebar">menu_open</mat-icon>
          <mat-icon class="menu-icon" *ngIf="!drawer.opened" matTooltip="Open sidebar">menu</mat-icon>
        </button>
      </div>
    </section>

    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
