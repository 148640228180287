<section class="contact-us-section">
  <div class="container">
    <div class="row">

      <div class="col-md-6">
        <div class="contact-detail">
          <h2>Contact us</h2>
          <p>Contact person - Nandhakumar A</p>
          <p>Phone number - (+91 9489792349)</p>
          <p>Send your query - query@vallift.com</p>
          <p>Email - nandha@vallift.com</p>
        </div>
      </div>

      <div class="col-md-6">
        <div class="shadow">
          <form [formGroup]="userQueryForm" (ngSubmit)="sendRequestClick()" class="d-flex flex-column contact-us-form">

            <h1>Got a project in mind?</h1>
            <p>Share the details of your project – like scope, timeframes, or requirement you'd like to solve. Will carefully study them and then we’ll figure out the next move together.</p>

            <div class="d-flex my-3">
              <mat-form-field class="input-50">
                <input formControlName="fullName" matInput placeholder="Full name">
                <mat-error *ngIf="userQueryForm.controls.fullName.hasError('required')">required</mat-error>
              </mat-form-field>
              <mat-form-field class="input-50">
                <input formControlName="companyName" matInput placeholder="Company name (Optional)">
              </mat-form-field>
            </div>

            <mat-form-field class="input-100 my-3">
              <input formControlName="email" matInput placeholder="Email address">
              <mat-error *ngIf="userQueryForm.controls.email.hasError('required')">required</mat-error>
              <mat-error *ngIf="userQueryForm.controls.email.hasError('email')">invalid email</mat-error>
            </mat-form-field>

            <mat-form-field class="input-100 my-3">
              <input formControlName="phone" matInput placeholder="Phone">
              <mat-error *ngIf="userQueryForm.controls.phone.hasError('required')">required</mat-error>
            </mat-form-field>

            <mat-form-field class="input-100 my-3">
              <textarea formControlName="projectDetail" class="project-detail-input" matInput placeholder="Project detail"></textarea>
              <mat-error *ngIf="userQueryForm.controls.projectDetail.hasError('required')">required</mat-error>
            </mat-form-field>

            <button mat-raised-button type="submit" class="mt-3 btn-send-request align-self-center">SEND REQUEST</button>

          </form>
        </div>
      </div>

    </div>
  </div>
</section>
