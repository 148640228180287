import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmailService} from '../../core/services/email.service';
import {ResponseStatus} from '../../core/utils/generic-response';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  saveUserQueryResponse$;

  userQueryForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private emailService: EmailService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.userQueryForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      companyName: [''],
      source: ['Website'],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      projectDetail: ['', Validators.required]
    });

    this.initObservers();
  }

  sendRequestClick() {
    if (this.userQueryForm.invalid) {
      return;
    }

    this.emailService.submitUserQuery(this.userQueryForm.value);
  }

  private initObservers() {
    this.saveUserQueryResponse$ = this.emailService.saveUserQueryResponse$.subscribe(res => {
      if (res.responseStatus === ResponseStatus.LOADING) {

      } else if (res.responseStatus === ResponseStatus.SUCCESS) {
        this.toastr.success('Submitted your query to Vallift Innovations, We will reach you soon!', 'Thank you!', );
        this.userQueryForm.reset();
      } else if (res.responseStatus === ResponseStatus.FAILED) {
        this.toastr.error('Please try again later.', 'Sorry:(', );
      }
    });
  }

  ngOnDestroy(): void {
    this.saveUserQueryResponse$.unsubscribe();
  }
}
