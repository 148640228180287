import { Injectable } from '@angular/core';
import {BaseApiService} from './base-api.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {GenericResponse} from '../utils/generic-response';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends BaseApiService {

  private readonly saveUserQueryResponse = new BehaviorSubject<GenericResponse<any>>(new GenericResponse<any>(null));
  readonly saveUserQueryResponse$ = this.saveUserQueryResponse.asObservable();

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  submitUserQuery(data: any) {
    const response = new GenericResponse<any>(this.saveUserQueryResponse);
    response.notifyLoading();

    super.post<any>('api/email/userQuery', data).subscribe(res => {
      response.notifySuccessAndPersistState(res);
    }, (error: HttpErrorResponse) => {
      response.notifyError('');
    });
  }
}
