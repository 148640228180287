<section id="how-we-work-section" class="how-we-work-section">
  <div class="main-title">
    <h1> HOW WE WORK </h1>
    <p> We use proven Software Development Life Cycle methodologies that makes communication with client and development team easy to achieve excellent productivity </p>
  </div>

  <div *ngIf="false" class="w-100 d-flex justify-content-center">
    <ul class="progressbar">
      <li>Discovery</li>
      <li class="active">Design</li>
      <li>Planning</li>
      <li>Development</li>
      <li>Final UAT & Launch</li>
    </ul>
  </div>

  <div class="flow-line">

    <div class="process">
      <span class="icon">1</span>
      <span class="heading">Discovery</span>
    </div>

    <div class="process">
      <span class="icon">2</span>
      <span class="heading">Design</span>
    </div>

    <div class="process">
      <span class="icon">3</span>
      <span class="heading">Planning</span>
    </div>

    <div class="process">
      <span class="icon">4</span>
      <span class="heading">Development</span>
    </div>

    <div class="process">
      <span class="icon">5</span>
      <span class="heading">Final UAT & Launch</span>
    </div>

  </div>

</section>

