<section class="footer-section container-fluid" id="footer-section">

  <div class="row">
    <div class="col-12 d-flex flex-column justify-content-center align-items-center about-caption">
      <h1>Let us know your ideas!</h1>
      <p>We are ready to hear ideas from you and join our hands to develop a better solution together.</p>
      <a class="drop-us-message btn-contact-us" routerLink="/contacts">Drop us a message</a>
    </div>
  </div>

  <div class="row about-detail">
    <div class="col-md-3 col-sm-12">
      <div class="branding d-flex flex-column justify-content-center align-items-center">
        <div class="vallift-brand">
          <a class="logo" href="#"><img height="60" src="../../../assets/images/branding/vallift-innovations-logo.svg" alt="logo"></a>

          <div class="name">
            <span class="vallift">Vallift</span>
            <span class="innovations">Innovations</span>
          </div>

        </div>
        <p class="vallift-description">Vallift Innovations iOS, Android, and the web development firm.</p>
      </div>
    </div>
    <div class="col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center justify-content-center my-5">
      <ul class="nav-menu">
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/">Home</a></li>
        <li><a routerLinkActive="active" routerLink="/services">Services</a></li>
        <li><a routerLinkActive="active" routerLink="/technologies">Technologies</a></li>
        <li><a routerLinkActive="active" routerLink="/portfolio">Portfolio</a></li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-12 flex-column justify-content-center align-items-center">
      <div class="contact-detail">
        <h2>Contact us</h2>
        <p>Contact person - Nandhakumar A</p>
        <p>Phone number - (+91 9489792349)</p>
        <p>Send your query - query@vallift.com</p>
        <p>Email - nandha@vallift.com</p>
      </div>
    </div>

  </div>

  <div class="row p-3">
    <div class="col-12">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <span class="copyright">©Copyright {{ getCurrentYear() }} - Vallift Innovations iOS, Android, and the web development firm. All Rights Reserved</span>
      </div>
    </div>

  </div>

</section>
