import {BehaviorSubject} from 'rxjs';

export class GenericResponse<T> {
  data: T = null;
  responseStatus: ResponseStatus = ResponseStatus.NONE;
  errorMessage: string = null;

  behaviorSubject: BehaviorSubject<GenericResponse<T>>;

  constructor(behaviorSubject: BehaviorSubject<GenericResponse<T>>) {
    this.behaviorSubject = behaviorSubject;
  }


  notifyLoading() {
    this.data = null;
    this.responseStatus = ResponseStatus.LOADING;
    this.errorMessage = null;
    this.behaviorSubject.next(this);
  }

  notifySuccessAndPersistState(data: T) {
    this.data = data;
    this.responseStatus = ResponseStatus.SUCCESS;
    this.errorMessage = null;

    this.behaviorSubject.next(this);

    this.responseStatus = ResponseStatus.PERSIST_STATE;
    this.behaviorSubject.next(this);
  }

  notifyError(errorMessage: string) {
    this.data = null;
    this.responseStatus = ResponseStatus.FAILED;
    this.errorMessage = errorMessage;
    
    this.behaviorSubject.next(this);
  }

  getData(): T {
    return this.data;
  }

}
export enum ResponseStatus {
  NONE = 0,
  LOADING = 1,
  SUCCESS = 2,
  FAILED = 3,
  PERSIST_STATE = 4
}
