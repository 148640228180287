<ng-http-loader
  [backdrop]="false"
  [backgroundColor]="'#f34538'"
  [debounceDelay]="100"
  [extraDuration]="300"
  [minDuration]="300"
  [opacity]="1"
  [spinner]="spinnerStyle">

</ng-http-loader>
<app-navigation>

  <router-outlet>
  </router-outlet>

  <app-footer></app-footer>
</app-navigation>


