<div class="portfolio-wrapper global-width-limiter">

  <div class="portfolio-item nirva-share">

    <div class="portfolio-card">
        <span class="project-detail">
          Client Project
        </span>
      <span class="project-detail">
          Web
        </span>
    </div>

    <div class="image-block">
      <div class="app-image d-flex flex-column justify-content-center align-items-center">
        <img class="mx-auto" src="../../../assets/images/nirvafiles.png">
      </div>
    </div>

    <div class="portfolio-wr">
      <h1>Nirva Share</h1>
      <p>File sharing and collaboration made simple for AWS S3, Azure blob, files, SFTP and many more.
        Share and collaborate any folder or file with your customers, partners, employees, etc with full security and
        fine access control.</p>
      <a type="button" target="_blank" href="http://nirvashare.com/" class="visit-website">Visit Website</a>
    </div>

  </div>

</div>

