<section id="services-section" class="services services-background container">

  <div class="main-title">
    <h1> OFFERINGS TO OUR CLIENTS </h1>
    <p> We provide IT solutions to brands and businesses with latest technology and we work with technology oriented companies to help in R&D, Software Architecture and Development </p>
  </div>

  <div class="services-cards row mb-4">
    <div class="col-md-4 col-sm-12">
      <div class="app-card">
        <img class="app-card-image" src="../../../assets/images/uiux.svg" alt="">
        <h1 class="app-card-title">UI/UX Design</h1>
        <ul class="app-service-list">
          <li>Prototyping</li>
          <li>Interface Design</li>
          <li>Interaction Design</li>
        </ul>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="app-card">
        <img class="app-card-image" src="../../../assets/images/mobile.svg" alt="">
        <h1 class="app-card-title">Mobile App Development</h1>
        <ul>
          <li>iOS Development</li>
          <li>Android App Development</li>
          <li>Cross platform Development</li>
        </ul>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="app-card">
        <img class="app-card-image" src="../../../assets/images/web.svg" alt="">
        <h1 class="app-card-title">Web App Development</h1>
        <ul>
          <li>Front-end Development</li>
          <li>Back-end Development</li>
          <li>Full Stack Development</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="services-cards row mb-4">
    <div class="col-md-4 col-sm-12">
      <div class="app-card">
        <img class="app-card-image" src="../../../assets/images/cloud.svg" alt="">
        <h1 class="app-card-title">Cloud Solutions</h1>
        <ul>
          <li>Devops</li>
          <li>Cloud Architecture</li>
          <li>Cloud Hosting</li>
        </ul>
      </div>
    </div>
  </div>

</section>
