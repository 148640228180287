<section id="technologies-section" class="container technologies-section technology-backgroud">
  <div class="main-title">
    <h1> TECHNOLOGIES WE USE</h1>
    <p> We use proven and scalable technological frameworks and approaches to implement scalable IT Solutions</p>
  </div>

  <div class="d-flex justify-content-center row">

    <div class="col-sm-12 col-md-4 technology-wrapper app-card">

      <h1 class="heading">Mobile</h1>

      <div class="technology-holder">
        <div class="tech-heading">
          <h1> Cross-Platform </h1>
          <div class="framework-container">
            <div class="framework">
              <img src="../../../assets/images/tech/flutter.svg" alt="Swift">
              <p>Flutter</p>
            </div>

          </div>
        </div>
      </div>

      <div class="technology-holder">
        <div class="tech-heading">
          <h1> iOS </h1>
          <div class="framework-container">
            <div class="framework">
              <img src="../../../assets/images/tech/swift.svg" alt="Swift">
              <p>Swift</p>
            </div>

          </div>
        </div>
        <div class="tech-heading">
          <h1> Android </h1>
          <div class="framework-container">
            <div class="framework">
              <img src="../../../assets/images/tech/java.svg" alt="Java">
              <p>Java</p>
            </div>

            <div class="framework">
              <img src="../../../assets/images/tech/kotlin.svg" alt="Kotlin">
              <p>Kotlin</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="col-sm-12 col-md-4 technology-wrapper app-card">

      <h1 class="heading">Web</h1>

      <div class="technology-holder">
        <div class="tech-heading">
          <h1> Frontend </h1>
          <div class="framework-container">
<!--            <div class="framework">-->
<!--              <img src="../../../assets/images/tech/html.svg" alt="Html">-->
<!--              <p>HTML</p>-->
<!--            </div>-->

<!--            <div class="framework">-->
<!--              <img src="../../../assets/images/tech/css.svg" alt="CSS">-->
<!--              <p>CSS</p>-->
<!--            </div>-->

<!--            <div class="framework">-->
<!--              <img src="../../../assets/images/tech/javascript.svg" alt="Javascript">-->
<!--              <p>Javascript</p>-->
<!--            </div>-->

            <div class="framework">
              <img src="../../../assets/images/tech/angular.svg" alt="Kotlin">
              <p>Angular</p>
            </div>

          </div>
        </div>
      </div>

      <div class="technology-holder">
        <div class="tech-heading">
          <h1> Backend </h1>
          <div class="framework-container">
            <div class="framework">
              <img src="../../../assets/images/tech/dotnet.svg" alt="Java">
              <p>.Net Core</p>
            </div>
            <div class="framework">
              <img src="../../../assets/images/tech/nestjs.svg" alt="Java">
              <p>NestJS</p>
            </div>
            <div class="framework">
              <img src="../../../assets/images/tech/springboot.svg" alt="Java">
              <p>Spring Boot</p>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="d-flex justify-content-center row">

    <div class="col-sm-12 col-md-4 technology-wrapper app-card">

      <h1 class="heading">Infrastructure</h1>

      <div class="technology-holder">
        <div class="tech-heading">
          <h1> Databases </h1>
          <div class="framework-container">
            <div class="framework">
              <img src="../../../assets/images/tech/mssql.svg" alt="Java">
              <p>MS SQL</p>
            </div>

            <div class="framework">
              <img src="../../../assets/images/tech/postgres.svg" alt="Kotlin">
              <p>Postgres SQL</p>
            </div>

            <div class="framework">
              <img src="../../../assets/images/tech/mysql.svg" alt="Kotlin">
              <p>My SQL</p>
            </div>

            <div class="framework">
              <img src="../../../assets/images/tech/oracle.svg" alt="Oracle">
              <p>Oracle</p>
            </div>

          </div>
        </div>
      </div>

      <div class="technology-holder">
        <div class="tech-heading">
          <h1> Cloud Hosting </h1>
          <div class="framework-container">
            <div class="framework">
              <img src="../../../assets/images/tech/azure.svg" alt="Java">
              <p>Azure</p>
            </div>
            <div class="framework">
              <img src="../../../assets/images/tech/aws.svg" alt="Java">
              <p>AWS</p>
            </div>
            <div class="framework">
              <img src="../../../assets/images/tech/digitalocean.svg" alt="Java">
              <p>DigitalOcean</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="col-sm-12 col-md-4 technology-wrapper app-card">

      <h1 class="heading">DevOps</h1>

      <div class="technology-holder">
        <div class="tech-heading">
          <h1> Tools </h1>
          <div class="framework-container">
            <div class="framework">
              <img src="../../../assets/images/tech/docker.svg" alt="Swift">
              <p>Docker</p>
            </div>
            <div class="framework">
              <img src="../../../assets/images/tech/kubernetes.svg" alt="Swift">
              <p>Kubernetes</p>
            </div>
            <div class="framework">
              <img src="../../../assets/images/tech/jenkins.svg" alt="Swift">
              <p>Jenkins</p>
            </div>
          </div>
        </div>
      </div>

      <div class="technology-holder">
        <div class="tech-heading">
          <h1> Cloud </h1>
          <div class="framework-container">
            <div class="framework">
              <img src="../../../assets/images/tech/bitbucket.svg" alt="Swift">
              <p>Bitbucket</p>
            </div>
            <div class="framework">
              <img src="../../../assets/images/tech/aws.svg" alt="Swift">
              <p>AWS DevOps</p>
            </div>
            <div class="framework">
              <img src="../../../assets/images/tech/azure.svg" alt="Swift">
              <p>Azure CI/CD</p>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</section>
