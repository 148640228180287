import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-info-privacy-policy',
  templateUrl: './location-info-privacy-policy.component.html',
  styleUrls: ['./location-info-privacy-policy.component.scss']
})
export class LocationInfoPrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
