import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {NavigationModule} from './features/navigation/navigation.module';
import { BannerComponent } from './features/banner/banner.component';
import { ServicesComponent } from './features/services/services.component';
import { FooterComponent } from './features/footer/footer.component';
import { TechnologiesComponent } from './features/technologies/technologies.component';
import { HowWeWorkComponent } from './features/how-we-work/how-we-work.component';
import {HomeComponent} from './features/home/home.component';
import {ContactUsComponent} from './features/contact-us/contact-us.component';
import {ToastrModule} from 'ngx-toastr';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {LocationInfoPrivacyPolicyComponent} from './features/location-info-privacy-policy/location-info-privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BannerComponent,
    ServicesComponent,
    FooterComponent,
    TechnologiesComponent,
    HowWeWorkComponent,
    ContactUsComponent,
    LocationInfoPrivacyPolicyComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NavigationModule,
    ToastrModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
