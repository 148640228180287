<div class="container m-3">
  <h1 class="mb-5">Privacy Policy</h1>
  <p>"My Location Info" application is build by Vallift Innovations.</p>
  <p>This privacy policy sets out how Vallift Innovation uses and protects any information that you give Vallift
    Innovations when you use this application.</p>
  <p>Vallift Innovations is committed to ensuring that your privacy is protected. Should we ask you to provide certain
    information by
    which you can be identified when using this application, then you can be assured that it will only be used in
    accordance with this privacy statement.</p>

  <h2>How We Collect and Use Information</h2>

  <p>You are allowed to use the application without creating an account, and when you create an account using Social
    login, we may collect your personal information such as your</p>
  <ol class="ml-2">
    <li>Username</li>
    <li>Email</li>
    <li>Photo</li>
  </ol>

  <p>The information that we request is will be retained by us and used as described in this privacy policy.</p>

  <p>The app does use third party services that may collect information used to identify you.</p>

  <p>Link to privacy policy of third party service providers used by the app</p>

  <ol class="ml-2">
    <li><a href="https://support.google.com/admob/answer/6128543?hl=en">AdMob</a></li>
    <li><a href="https://firebase.google.com/policies/analytics">Firebase Analytics</a></li>
    <li><a href="http://try.crashlytics.com/terms/privacy-policy.pdf">Crashlytics</a></li>
  </ol>

  <h3>Location Service</h3>
  <p>Application uses your GPS to find precised location in order to fulfill the functionality of the application.</p>
  <p>Your GPS is used to get your current geo coordinates and further using secured third party systems your Address and
    Weather data are fetched to display you those information.</p>
  <p>And Application uses GPS data to save your parking location.</p>
  <p>All the data collected are not used for any analytics and not given to any third-parties.</p>

  <h3>Camera Access</h3>
  <p>Application uses camera to capture the parking location, which is a feature of the application.</p>

  <h2>Log Data</h2>
  <p>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol ('IP') address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>

  <h2>Security</h2>
  <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

  <h2>Changes to This Privacy Policy</h2>
  <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

  <h2>Contact Us</h2>
  <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at nandha@vallift.com</p>

</div>
